// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biodiversity-js": () => import("./../../../src/pages/biodiversity.js" /* webpackChunkName: "component---src-pages-biodiversity-js" */),
  "component---src-pages-cadetship-js": () => import("./../../../src/pages/cadetship.js" /* webpackChunkName: "component---src-pages-cadetship-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-korero-girls-war-js": () => import("./../../../src/pages/korero-girls-war.js" /* webpackChunkName: "component---src-pages-korero-girls-war-js" */),
  "component---src-pages-korero-history-js": () => import("./../../../src/pages/korero-history.js" /* webpackChunkName: "component---src-pages-korero-history-js" */),
  "component---src-pages-korero-kororareka-js": () => import("./../../../src/pages/korero-kororareka.js" /* webpackChunkName: "component---src-pages-korero-kororareka-js" */),
  "component---src-pages-korero-land-js": () => import("./../../../src/pages/korero-land.js" /* webpackChunkName: "component---src-pages-korero-land-js" */),
  "component---src-pages-korero-pepeha-js": () => import("./../../../src/pages/korero-pepeha.js" /* webpackChunkName: "component---src-pages-korero-pepeha-js" */),
  "component---src-pages-korero-principles-js": () => import("./../../../src/pages/korero-principles.js" /* webpackChunkName: "component---src-pages-korero-principles-js" */),
  "component---src-pages-korero-tohu-js": () => import("./../../../src/pages/korero-tohu.js" /* webpackChunkName: "component---src-pages-korero-tohu-js" */),
  "component---src-pages-korero-treaty-js": () => import("./../../../src/pages/korero-treaty.js" /* webpackChunkName: "component---src-pages-korero-treaty-js" */),
  "component---src-pages-korero-waiata-js": () => import("./../../../src/pages/korero-waiata.js" /* webpackChunkName: "component---src-pages-korero-waiata-js" */),
  "component---src-pages-korero-war-js": () => import("./../../../src/pages/korero-war.js" /* webpackChunkName: "component---src-pages-korero-war-js" */),
  "component---src-pages-korero-whakapapa-ngati-tu-js": () => import("./../../../src/pages/korero-whakapapa-ngati-tu.js" /* webpackChunkName: "component---src-pages-korero-whakapapa-ngati-tu-js" */),
  "component---src-pages-marae-booking-js": () => import("./../../../src/pages/marae-booking.js" /* webpackChunkName: "component---src-pages-marae-booking-js" */),
  "component---src-pages-marae-committee-js": () => import("./../../../src/pages/marae-committee.js" /* webpackChunkName: "component---src-pages-marae-committee-js" */),
  "component---src-pages-marae-history-js": () => import("./../../../src/pages/marae-history.js" /* webpackChunkName: "component---src-pages-marae-history-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-registration-form-js": () => import("./../../../src/pages/registration-form.js" /* webpackChunkName: "component---src-pages-registration-form-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-tahuhu-js": () => import("./../../../src/pages/tahuhu.js" /* webpackChunkName: "component---src-pages-tahuhu-js" */),
  "component---src-pages-tahuhu-representatives-js": () => import("./../../../src/pages/tahuhu-representatives.js" /* webpackChunkName: "component---src-pages-tahuhu-representatives-js" */),
  "component---src-pages-tahuhu-representatives-specifications-js": () => import("./../../../src/pages/tahuhu-representatives-specifications.js" /* webpackChunkName: "component---src-pages-tahuhu-representatives-specifications-js" */),
  "component---src-pages-tahuhu-strategy-js": () => import("./../../../src/pages/tahuhu-strategy.js" /* webpackChunkName: "component---src-pages-tahuhu-strategy-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

